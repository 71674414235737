import React from 'react'

import {AnimatePresence, motion} from 'framer-motion'

import PoshHeader from '../../pages/OwnerPage/PoshHeader'
import {AppStoreButtons} from '../AppStore/AppStoreButtons'
import {PoshImage} from '../PoshImage/PoshImage'

import styles from './styles.module.scss'

interface MobileAppExclusiveFeatureOverlayProps {
  title: string
  image: string
}

export const MobileAppExclusiveFeatureOverlay = (props: MobileAppExclusiveFeatureOverlayProps) => {
  const {title, image} = props

  return (
    <>
      <PoshHeader hideMenuItems isHalfTranslucent />
      <AnimatePresence>
        <motion.div
          className={styles.mobileAppExclusiveFeatureOverlay}
          animate={{opacity: [0, 1], transition: {duration: 0.5}}}>
          <div className={styles.contentsContainer}>
            <h3>{title}</h3>
            <AppStoreButtons />
          </div>
          <div className={styles.imageContainer}>
            <PoshImage src={image} />
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  )
}
