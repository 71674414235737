import {useQuery} from '@tanstack/react-query'
import {AccountModel, AccountModelPublic} from 'apis/Accounts'
import {OrderTicketModel} from 'apis/Orders'
import PoshApi from 'apis/PoshApi'
import {RoleKey} from 'apis/Roles/Role'

import useSessionContext from '../../domains/Auth/SessionContext'

export interface EventAnalyticsResponse {
  pageVisits: number
  totalTickets: number
  totalRevenue: number
  outstandingPayments: number
  totalCollectedFees?: number
  ordersByDayRevenueMap: {
    [key: string]: {
      totalTickets: number
      totalRevenue: number
      totalPaymentPlanRevenue?: number
    }
  }
  ticketGenderMap: {
    [key: string]: AccountModelPublic & Pick<AccountModel, 'detectedGender'>[]
  }
  ticketTypeMap: {
    [key: string]: OrderTicketModel[]
  }
  donationTotal?: number
}

export async function fetchEventAnalytics(
  eventId: string,
  domain: RoleKey,
  hostId?: string,
): Promise<EventAnalyticsResponse> {
  const response = await PoshApi.fetchEventAnalytics(eventId, domain, hostId)
  return response.data.result
}

export default function useFetchEventAnalytics(eventId: string | null | undefined, domain?: RoleKey) {
  const {userId} = useSessionContext()
  return useQuery(
    ['eventAnalytics', eventId, domain],
    () => {
      if (!eventId || !domain) {
        return undefined
      } else {
        return fetchEventAnalytics(eventId, domain, userId ?? undefined)
      }
    },
    {
      enabled: !!eventId,
    },
  )
}
