import {EventResponse} from 'apis/Accounts/useFetchAllAttendeeOrdersAndEvents'
import {EventPublic} from 'apis/Events/types'
import {createEvent} from 'ics'
import {CalendarHelpers} from 'pages/EventPage/AddToCalendarModal/helpers'

export const downloadCalendar = (event: EventPublic | EventResponse) => {
  try {
    const {
      name: title,
      description,
      location,
      startUtc,
      endUtc,
    } = CalendarHelpers.getEventCalendarLinkParams(event) ?? {}
    if (!startUtc || !endUtc) throw new Error('Invalid event dates')
    const [start, end] = [CalendarHelpers.getIcsDateArray(startUtc), CalendarHelpers.getIcsDateArray(endUtc)]
    createEvent(
      {
        title,
        startInputType: 'utc',
        endInputType: 'utc',
        description,
        busyStatus: 'BUSY',
        start,
        end,
        location,
        url: `https://posh.vip/e/${event.url}`,
        status: 'CONFIRMED',
      },
      (error, value) => {
        if (error) return null
        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(value))
        element.setAttribute('download', 'calendar.ics')
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      },
    )
  } catch (e) {
    console.log('Calendar download failed: ', e)
  }
}
