import React, {useState} from 'react'

import {CurrencyCode} from '@posh/types'
import {useQueryClient} from '@tanstack/react-query'
import {OrderModel} from 'apis/Orders'
import useCancelOrder from 'apis/Orders/useCancelOrder'
import useRefundOrder from 'apis/Orders/useRefundOrder'
import PoshApi from 'apis/PoshApi'
import Button from 'components/form/Button'
import PoshInfo from 'components/form/PoshInfo'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import {useToast} from 'components/toasts/ToastProvider'
import PartialRefundModal from 'pages/GroupPage/Marketing/AttendeeProfilePage/PartialRefundModal'

interface OrderActionRowProps {
  order: OrderModel
  currency?: CurrencyCode
}

const OrderActionRow = (props: OrderActionRowProps) => {
  const {order, currency} = props
  const {mutateAsync: sendRefund, isLoading: isRefunding} = useRefundOrder()
  const {mutateAsync: cancelOrder, isLoading: isCanceling} = useCancelOrder()
  const queryClient = useQueryClient()

  const [isShowingPartialRefundModal, setIsShowingPartialRefundModal] = useState(false)
  const {showToast} = useToast()
  const orderIsAssociatedWithATransfer =
    !!order.fromTransferDocId || !!order.tickets?.some(t => !!t.sentToTransferDocId)

  const resendReceiptDisabled = orderIsAssociatedWithATransfer
  const refundButtonDisabled =
    isRefunding || order.refunded === true || !!order.partialRefund || orderIsAssociatedWithATransfer
  const partialRefundButtonDisabled = isRefunding || order.refunded === true || orderIsAssociatedWithATransfer
  const cancelButtonDisabled = isCanceling || order.canceled === true || orderIsAssociatedWithATransfer
  const refundButtonText = order.refunded ? (order.availableForResell ? 'Refunded and Relisted' : 'Refunded') : 'Refund'
  const cancelButtonText = order.canceled ? (order.availableForResell ? 'Canceled and Relisted' : 'Canceled') : 'Cancel'
  const shouldDisplayPartialRefundButton = order.partialRefund !== order.subtotal && !order.refunded
  const loadingText = isRefunding ? 'Sending Refund...' : 'Canceling Order...'

  const resendReceipt = async () => {
    await PoshApi.sendOrderReceipt(order._id, currency ?? 'USD')
    showToast({type: 'success', title: 'Receipt email sent'})
  }

  const refetchOrderPage = async () => {
    await queryClient.invalidateQueries(['order', order._id])
  }

  const onCancel = async (isRelisting: boolean) => {
    try {
      const canceledResponse = await cancelOrder({orderId: order._id, isRelisting})
      if (isRelisting) showToast({type: 'success', title: 'Order Canceled and Relisted'})
      else showToast({type: 'success', title: canceledResponse})
      await refetchOrderPage()
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: 'Error Canceling Order'})
    }
  }

  const onRefund = async (isReselling: boolean) => {
    try {
      if (!order.eventID) throw new Error('There was an error refunding this order.')
      const refundResponse = await sendRefund({
        orderId: order._id,
        eventId: order.eventID,
        isReselling,
      })
      showToast({type: 'success', title: refundResponse})
      await refetchOrderPage()
    } catch (error: any) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  const showPartialRefundModal = () => {
    setIsShowingPartialRefundModal(true)
  }

  return (
    <div className='OrderActionRow-card'>
      <div className='OrderActionRow-buttonContainer'>
        {isRefunding || isCanceling ? (
          <>
            <p className='OrderActionRow-buttonContainer-refunding'>{loadingText}</p>
            <SpinLoader height={30} />
          </>
        ) : (
          <>
            <RequirePermissions requiredPermissions={[requirePermissionAndScope('resend_receipt', ['group', 'event'])]}>
              <Button className='gold square' onClick={resendReceipt} disabled={resendReceiptDisabled}>
                Resend Receipt
              </Button>
            </RequirePermissions>
            {order.subtotal === 0 ? (
              <>
                <RequirePermissions requiredPermissions={[requirePermissionAndScope('refund', ['group', 'event'])]}>
                  <Button className='gold square' disabled={cancelButtonDisabled} onClick={() => onCancel(false)}>
                    {cancelButtonText}
                  </Button>
                  {!order.canceled && (
                    <Button className='gold square' disabled={cancelButtonDisabled} onClick={() => onCancel(true)}>
                      Cancel &amp; Relist
                    </Button>
                  )}
                </RequirePermissions>
              </>
            ) : (
              <>
                {shouldDisplayPartialRefundButton && (
                  <RequirePermissions requiredPermissions={[requirePermissionAndScope('refund', ['group', 'event'])]}>
                    <Button
                      className='gold square'
                      disabled={partialRefundButtonDisabled}
                      onClick={showPartialRefundModal}>
                      Partial Refund
                    </Button>
                  </RequirePermissions>
                )}

                {!order.partialRefund && (
                  <>
                    <RequirePermissions requiredPermissions={[requirePermissionAndScope('refund', ['group', 'event'])]}>
                      <Button className='gold square' disabled={refundButtonDisabled} onClick={() => onRefund(false)}>
                        {refundButtonText}
                      </Button>
                    </RequirePermissions>
                    {!order.refunded && (
                      <RequirePermissions
                        requiredPermissions={[requirePermissionAndScope('refund_and_resell', ['group', 'event'])]}>
                        <div style={{marginTop: 'auto', marginBottom: 'auto', display: 'flex', alignItems: 'center'}}>
                          <Button
                            className='gold square'
                            disabled={refundButtonDisabled}
                            onClick={() => onRefund(true)}>
                            Refund &amp; Relist
                          </Button>
                          <PoshInfo
                            info={'The buyer will be refunded and the tickets will be available for purchase again.'}
                          />
                        </div>
                      </RequirePermissions>
                    )}
                  </>
                )}
                {isShowingPartialRefundModal && (
                  <PartialRefundModal
                    className='PartialRefundModal-container'
                    isOpen={isShowingPartialRefundModal}
                    onClose={() => setIsShowingPartialRefundModal(false)}
                    order={order}
                    currency={currency}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default OrderActionRow
