import React, {PropsWithChildren, useEffect, useMemo} from 'react'

import {OptimizelyProvider as OriginalOptimizelyProvider} from '@optimizely/react-sdk'
import {v4 as uuidv4} from 'uuid'

import {optimizelyClient} from '../apis/OptimizelyHandler'

// This snippit comes from the support team at Optimizely. It is a function that cleans up local storage if it is over 4MB.
export const cleanUpOptimizely = () => {
  // Function that returns size of local storage on current origin (max is 5 MB)
  const localStorageSpace = () => {
    const CHAR_SIZE_BYTES = 2
    const BYTES_IN_KB = 1024
    let sizeBytes = 3 // start with 3 bytes to account for possible overhead that isn't included in the localstorage string
    for (const key in window.localStorage) {
      if (window.localStorage.hasOwnProperty(key)) {
        sizeBytes += window.localStorage[key].length * CHAR_SIZE_BYTES
      }
    }
    return sizeBytes / BYTES_IN_KB
  }

  const sizeLocalStorage = localStorageSpace()

  // If the local storage space is above 4MB (5 MB is the limit per origin)
  if (sizeLocalStorage > 4000) {
    // Disable cross origin sync
    window['optimizely'].push({
      type: 'disableCrossOrigin',
    })
    // Delete all optimizely related local storage data
    for (const key in window.localStorage) {
      if (key.indexOf('optimizely') !== -1) {
        window.localStorage.removeItem(key)
      }
    }
  }
}

export const PoshOptimizelyProvider: React.FC<PropsWithChildren> = ({children}) => {
  const isOptEditor = new URLSearchParams(window.location.search).has('optimizely_editor')

  const useInititateOptimizely = () => {
    const id = useMemo(() => uuidv4(), [])
    if (isOptEditor) {
      return {optimizelyId: undefined, id}
    }
    const optId = window['optimizely']?.get('visitor_id') ?? undefined
    return {optimizelyId: optId, id}
  }

  const {optimizelyId, id} = useInititateOptimizely()

  useEffect(() => {
    cleanUpOptimizely()
  }, [])

  const ProviderComponent = isOptEditor ? React.Fragment : OriginalOptimizelyProvider

  return (
    <ProviderComponent optimizely={optimizelyClient} user={{id: optimizelyId?.randomId ?? id}}>
      {children}
    </ProviderComponent>
  )
}
