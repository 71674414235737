import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useFetchAccountGroups} from 'apis/Accounts/useFetchAccountGroups'
import useCheckPoshAdmin from 'apis/AdminDashboard/useCheckPoshAdmin'
import useFetchCommunityEntity from 'apis/Community/useFetchCommunityEntity'
import useCheckIsAffiliate from 'apis/Kickback/useCheckIsAffiliate'
import {useMixpanel} from 'apis/MixPanelHandler'
import {DB_BACKED_FEATURES, FeatureFlag} from 'components/FeatureFlag'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {PoshLogo} from 'components/PoshLogo'
import RequirePermissions from 'components/RequirePermissions'
import useSessionContext from 'domains/Auth/SessionContext'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import useLogout from 'hooks/useLogout'

import {useNavigateToCreateEvent} from '../../../CreateEvent/useNavigateToCreateEvent'
import {NavigationOrgSelect} from './components/OrgSelect'
import {useGetCurrentGroup} from './components/OrgSelect/useGetCurrentGroup'

import './styles.scss'

interface SideBarProps {
  isSidebarDeployed: boolean
  setIsSidebarDeployed: React.Dispatch<React.SetStateAction<boolean>>
  avi: string
}

const SideBar = (props: SideBarProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {userId} = useSessionContext()
  const {data: communityEntity} = useFetchCommunityEntity()
  const {isSidebarDeployed, setIsSidebarDeployed, avi} = props
  const {groupId} = useGroupMatchParams()
  const {data: accountGroupUnions} = useFetchAccountGroups()
  const {data: check, isFetched} = useCheckPoshAdmin()
  const navigate = useNavigate()
  const logout = useLogout()
  type PageRoute = 'dashboard/account' | 'dashboard/community' | 'dashboard/orders' | 'dashboard/kickback' | 'admin'

  const {data: isAnAffiliate, isFetching: isFetchingIsAffiliate} = useCheckIsAffiliate(userId!)
  const {navigateToCreateEvent} = useNavigateToCreateEvent()

  const {currentGroup} = useGetCurrentGroup(accountGroupUnions, groupId)

  const navToPage = (route: PageRoute) => {
    navigate({pathname: `/${route}`})
    setIsSidebarDeployed(false)
    return
  }

  const navToCreateEvent = () => {
    trackMixpanelEvent('Create New Event-  Curator side bar')
    navigateToCreateEvent({groupId: currentGroup?.group._id, optimizelyEntryPoint: 'Sidebar'})
    setIsSidebarDeployed(false)
    return
  }

  const navToCurrentGroup = () => {
    navigate({pathname: `/${currentGroup?.accountRole.roleKey}/groups/${currentGroup?.group._id}/overview`})
    setIsSidebarDeployed(false)
    return
  }

  return (
    <div className={`Sidebar-Wrapper ${isSidebarDeployed ? 'deployed' : ''}`}>
      <div className={`Sidebar ${isSidebarDeployed ? 'deployed' : ''}`}>
        <div>
          {currentGroup && (
            <div className='Sidebar-Auth'>
              <div className='Sidebar-Auth-OrgSelectBlock' />
              <div className='Sidebar-Auth-OrgSelectContainer'>
                <NavigationOrgSelect.AccountGroupUnion.WithData
                  onPressCreateNewOrganization={() => setIsSidebarDeployed(false)}
                  onSelectGroupAccountRoleUnion={() => {
                    trackMixpanelEvent('Org Dropdown Organization Clicked-  Curator Sidebar')
                    setIsSidebarDeployed(false)
                  }}
                  onExpand={() => trackMixpanelEvent('Org Dropdown Click-  Curator Sidebar')}
                />
              </div>
              <PoshImage src={avi} className='PoshHeader-person' />
            </div>
          )}
          {currentGroup && (
            <RequirePermissions
              requiredPermissions={[
                {
                  permissionKey: 'create_event',
                  applicableScopes: ['group'],
                },
              ]}>
              <div className='Sidebar-Action'>
                <button
                  onClick={() => {
                    navToCreateEvent()
                  }}>
                  + Create New Event
                </button>
              </div>
            </RequirePermissions>
          )}
          <div className='Sidebar-Nav'>
            {currentGroup && (
              <div
                onClick={() => {
                  trackMixpanelEvent('Organization Dashboard Click-  Curator Sidebar')
                  navToCurrentGroup()
                }}>
                Organization Dashboard
              </div>
            )}
            <div
              onClick={() => {
                trackMixpanelEvent('My Orders Click-  Curator Sidebar')
                navToPage('dashboard/orders')
              }}>
              My Orders
            </div>
            {!isFetchingIsAffiliate && isAnAffiliate && (
              <div
                onClick={() => {
                  trackMixpanelEvent('Kickback Click-  Curator Sidebar')
                  navToPage('dashboard/kickback')
                }}>
                Kickback
              </div>
            )}
            {communityEntity?.type && <div onClick={() => navToPage('dashboard/community')}>Community</div>}
            <div
              onClick={() => {
                trackMixpanelEvent('My Account Click-  Curator Sidebar')
                navToPage('dashboard/account')
              }}>
              My Account
            </div>
            {check?.authorized && isFetched && (
              <div
                onClick={() => {
                  trackMixpanelEvent('Admin Dashboard Click-  Curator Sidebar')
                  navToPage('admin')
                }}>
                Admin Dashboard
              </div>
            )}
            <FeatureFlag.DBBacked featureName={DB_BACKED_FEATURES.MANAGE_EVENT_PLAYLISTS}>
              <div
                onClick={() => {
                  navigate({pathname: '/manage-event-playlists'})
                }}>
                Manage Event Playlists
              </div>
            </FeatureFlag.DBBacked>
            <div
              className='faded'
              onClick={() => {
                trackMixpanelEvent('Logout Click-  Curator Sidebar')
                logout()
              }}>
              Logout
            </div>
          </div>
        </div>
        <div className='Sidebar-Logo'>
          <PoshLogo.Link />
        </div>
      </div>
      <div style={{flexGrow: 1}} onClick={() => setIsSidebarDeployed(false)}></div>
    </div>
  )
}

export default SideBar
