import React from 'react'

import {Card} from 'components/Card'
interface OrderCardFraudAnalysisProps {
  riskScore: number
  riskLevel: string
}

const OrderCardFraudAnalysis = (props: OrderCardFraudAnalysisProps) => {
  const {riskScore, riskLevel} = props

  const width = `${riskScore}%`

  const background = (() => {
    if (riskLevel === 'normal') return 'lime'
    if (riskLevel === 'not_assessed' || riskLevel === 'unknown') return 'grey'
    if (riskLevel === 'elevated') return 'orange'
    return 'red'
  })()

  return (
    <Card
      title={'Fraud Risk Analysis'}
      animationIndex={5}
      info={
        'This is the likelihood that the payment for this order is fraudulent, on a scale of 0-100, evaluated by Stripe.'
      }>
      <div className='OrderPage-fraudBar-score'>{riskScore} / 100</div>

      <div className='OrderPage-fraudBar'>
        <div className='OrderPage-fraudBar-bar'>
          <div className='OrderPage-fraudBar-bar-inner' style={{width: width, background: background}} />
        </div>
        <div className='OrderPage-fraudBar-labels'>
          <span>LOWEST</span>
          <span>NORMAL</span>
          <span>ELEVATED</span>
          <span>HIGHEST</span>
        </div>
      </div>
    </Card>
  )
}

export default OrderCardFraudAnalysis
