import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {isValidPhoneNumber} from '@posh/utils'
import {useLoginWithPhone} from 'apis/Auth/useLoginWithPhone'
import {facebookPixelTriggerTrack, POSH_PIXEL_ID} from 'apis/FbPixelHelpers'
import {useMixpanel} from 'apis/MixPanelHandler'
import {browserNavigate} from 'apis/NavigationHelper'
import CloudflareTurnstile, {TurnstileRef} from 'components/CloudflareTurnstile'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import PhoneNumberInput from 'components/form/PhoneNumberInput'
import {useToast} from 'components/toasts/ToastProvider'
import {useDimensions} from 'hooks/useDimensions'
import {isNull, isUndefined} from 'lodash'
import {VerificationCodeInput} from 'pages/LoginPage/VerificationCodeInput'

import {useSignUp} from '../../../apis/Auth/useSignUp'
import useSessionContext from '../../../domains/Auth/SessionContext'
import {SideArt} from './SideArt'
import {SignupSecondStep} from './SignupSecondStep'
import TOSCheckForm from './TOSCheckForm'

import './../onboarding.scss'

const SignUp = () => {
  const {isMobile} = useDimensions()
  const urlParams = new URLSearchParams(window.location.search)
  const encodedRedirectUrl = urlParams.get('r')
  const attributionId = urlParams.get('a') || undefined
  const isCommunityRoute = !isNull(urlParams.get('c')) && urlParams.get('c') === 'true'
  const token = urlParams.get('token')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [checkForms, setCheckForms] = useState({
    tos: true,
    smsOptIn: true,
  })
  const [phone, setPhoneNumber] = useState('')
  const [isShowingNextStep, setIsShowingNextStep] = useState(false)
  const [hasSubmittedOnceWithoutSMSOptIn, setHasSubmittedOnceWithoutSMSOptIn] = useState(false)
  const {showToast} = useToast()

  const navigate = useNavigate()
  const {mutateAsync: signUp, isLoading} = useSignUp()
  const {mutateAsync: loginWithPhone, isLoading: isLoggingInWithPhone, error: loginError} = useLoginWithPhone()
  const [hasExistingAccount, setHasAccount] = useState<boolean | undefined>(undefined)
  const [needsToConfirmAccount, setNeedsToConfirmAccount] = useState<boolean | undefined>(undefined)
  const {userId} = useSessionContext()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const [captchaToken, setCaptchaToken] = useState<string | undefined>()
  const captchaRef = React.useRef<TurnstileRef>(null)

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const referredBy = query.get('t')

  useEffect(() => {
    if (userId) {
      if (hasExistingAccount) {
        browserNavigate('/')
      }
      setIsShowingNextStep(true)
    }
  }, [history, userId])

  const handleSignupError = (error: any) => {
    // It would be great to standardized the error handling across these auth pages
    if (loginError) {
      trackMixpanelEvent('Signup Failure- Sign Up Join Posh Page', {reasonForFailure: loginError.message})
      setErrorMessage(loginError.message)
    } else if (error.message) {
      trackMixpanelEvent('Signup Failure- Sign Up Join Posh Page', {reasonForFailure: error.message})
      setErrorMessage(error.message)
    } else if (error.response) {
      trackMixpanelEvent('Signup Failure- Sign Up Join Posh Page', {reasonForFailure: error.response.data.error})
      setErrorMessage(error.response.data.error)
    } else {
      trackMixpanelEvent('Signup Failure- Sign Up Join Posh Page', {reasonForFailure: error.message})
      setErrorMessage(error.message)
    }
  }

  const onSubmit = async () => {
    try {
      trackMixpanelEvent('Join Posh Sign Up-  Sign Up Join Posh Page')
      setErrorMessage('')
      if (!firstName || !lastName) throw new Error('Please fill out all fields')
      if (!email) throw new Error('Please fill out all fields')
      if (!token) {
        if (!phone || !password || !confirmPassword) throw new Error('Please fill out all fields')
        if (!isValidPhoneNumber(phone)) throw new Error('Please enter a valid phone number.')
        if (password !== confirmPassword) throw new Error('Passwords do not match')
      }
      if (!checkForms.tos) throw new Error('Must read and accept terms of service and privacy policy to sign up')
      if (!checkForms.smsOptIn && !hasSubmittedOnceWithoutSMSOptIn) {
        setHasSubmittedOnceWithoutSMSOptIn(true)
        return showToast({
          type: 'warning',
          title: 'Please opt in to text notifications for important event-related alerts.',
        })
      }
      if (token) {
        await postVerificationSignup(token)
        return
      }
      if (!captchaToken) {
        showToast({type: 'error', title: 'Please complete the captcha'})
        return
      }
      if (captchaRef.current?.isExpired()) {
        showToast({type: 'error', title: 'Captcha has expired. Please try again'})
        resetCaptcha()
        return
      }
      const {hasAccount, needsToConfirmAccount} = await loginWithPhone({phone, cfTurnstileResponse: captchaToken})
      setHasAccount(hasAccount)
      setNeedsToConfirmAccount(needsToConfirmAccount)
    } catch (error) {
      handleSignupError(error)
      resetCaptcha()
    }
  }

  const postVerificationSignup = async (signupToken: string) => {
    try {
      await signUp({
        email,
        password: password || undefined,
        firstName,
        lastName,
        phone,
        smsOptIn: checkForms.smsOptIn,
        referredBy: referredBy ?? undefined,
        verificationToken: signupToken,
        attributionId,
      })
      trackMixpanelEvent('Signup Success- Sign Up Join Posh Page')
      const data = {
        content_category: 'Finished Signup Flow',
        content_name: 'Successfully Signed-Up New User on POSH',
      }
      facebookPixelTriggerTrack('CompleteAccountSignUp', data, POSH_PIXEL_ID)
      if (encodedRedirectUrl) {
        const decodedRedirectUrl = decodeURIComponent(encodedRedirectUrl)
        navigate(decodedRedirectUrl)
        return
      }
      setIsShowingNextStep(true)
    } catch (error) {
      handleSignupError(error)
      //If the verification signup fails, the user needs a new verification code.
      setHasAccount(undefined)
      setNeedsToConfirmAccount(undefined)
    }
  }

  const onClickAlreadyHaveAccountLogin = () => {
    trackMixpanelEvent('Already Have An Account? Login- Sign Up Join Posh Page')
  }

  const handleCaptcha = (token: string) => {
    setCaptchaToken(token)
  }

  const resetCaptcha = () => {
    // Reset the captcha token in our current state since it's no longer valid
    setCaptchaToken(undefined)
    // Tell the captcha widget to reset its internal state and start over a new captcha challenge
    // This doesn't trigger the reset callback, which is why we have to manually clear the state above
    captchaRef.current?.reset()
  }

  return (
    <div className='OnboardPage'>
      <SideArt isCommunityRoute={isCommunityRoute} />
      <div className='OnboardPage-content'>
        {isShowingNextStep ? (
          <SignupSecondStep referredBy={referredBy} />
        ) : isUndefined(hasExistingAccount) ? (
          <>
            <div className='OnboardPage-content-login'>
              Already have an account?{' '}
              <a href='/login' onClick={onClickAlreadyHaveAccountLogin}>
                Log in
              </a>
            </div>
            <h2>Sign Up</h2>
            <div className='formWrapper'>
              {errorMessage.length > 0 && <p className='error noMargin'>{errorMessage}</p>}
              <div className='inputWrapper fullWidth'>
                <Input
                  type='text'
                  name='firstName'
                  className='fullWidth'
                  placeholder='First Name'
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
                <Input
                  type='text'
                  name='lastName'
                  className='fullWidth'
                  placeholder='Last Name'
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required
                />
              </div>
              <Input
                type='email'
                name='email'
                placeholder='Email'
                value={email}
                className='fullWidth'
                onChange={e => setEmail(e.target.value)}
              />
              {!token && (
                <>
                  <PhoneNumberInput
                    placeholder='Phone'
                    defaultCountry='US'
                    value={phone ?? ''}
                    onChange={e => {
                      if (e) setPhoneNumber(e)
                    }}
                  />
                  <Input
                    type='password'
                    name='password'
                    placeholder='Password'
                    className='fullWidth'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <Input
                    type='password'
                    name='confirmPassword'
                    placeholder='Confirm Password'
                    className='fullWidth'
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                </>
              )}
              <TOSCheckForm checkForms={checkForms} setCheckForms={setCheckForms} />
              <CloudflareTurnstile
                ref={captchaRef}
                successCallback={handleCaptcha}
                resetCaptchaCallback={() => setCaptchaToken(undefined)}
              />
              <Button
                className='Button'
                onClick={onSubmit}
                isLoading={isLoading || isLoggingInWithPhone}
                disabled={isLoading || isLoggingInWithPhone || !captchaToken}>
                Join Posh
              </Button>
            </div>
          </>
        ) : (
          <VerificationCodeInput
            phone={phone}
            goBack={() => {
              setHasAccount(undefined)
              setNeedsToConfirmAccount(undefined)
            }}
            isMobile={isMobile}
            setErrorMessage={setErrorMessage}
            hasExistingAccount={hasExistingAccount!}
            needsToConfirmAccount={needsToConfirmAccount!}
            signupCallback={postVerificationSignup}
            goBackOnError={true}
          />
        )}
      </div>
    </div>
  )
}

export default SignUp
