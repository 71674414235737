import React from 'react'
import {Link as RouterLink} from 'react-router-dom'

import {Currency} from '@posh/utils'
import {GetEventOrdersOutput} from 'apis/Orders/useGetEventOrders'
import classNames from 'classnames'
import {PoshImage} from 'components/PoshImage/PoshImage'
import {useGroupMatchParams} from 'domains/Groups/helpers'
import {upperCase} from 'lodash'
import moment from 'moment-timezone'
import {useEventManagementContext} from 'pages/EventManagementPages/EventManagementContext'

import {Card} from '../../Shared/Card/Card'
import {Text} from '../../Shared/Text/Text'

import styles from './OrderItem.module.scss'

const Link = (props: Parameters<typeof RouterLink>[0]) => {
  return (
    <RouterLink {...props} className={styles.Link}>
      {props.children}
    </RouterLink>
  )
}

type TagVariant = GetEventOrdersOutput[number]['tags'][number]
const Tag = ({variant}: {variant: TagVariant}) => {
  return (
    <div className={classNames(styles.TagContainer, styles[variant])}>
      <Text size='small' bold className={classNames(styles.Tag, styles[variant])}>
        {upperCase(variant)}
      </Text>
    </div>
  )
}

export const OrderItem = (props: GetEventOrdersOutput[number]) => {
  const {
    eventName,
    orderNumber,
    orderDate,
    orderSubtotal,
    orderCurrency,
    accountName,
    accountAvatar,
    orderId,
    accountId,
    tags,
  } = props
  const {
    event: {url},
  } = useEventManagementContext()
  // TODO: abstract event page navigation? Maybe part of nav rework?
  const {domain, groupId} = useGroupMatchParams()
  const orgScopeAttendeePage = accountId ? `/${domain}/groups/${groupId}/marketing/attendee/${accountId}` : null
  const orgScopeOrdersPage = `/${domain}/groups/${groupId}/orders/${orderId}`

  const {event} = useEventManagementContext()
  const isRSVPEvent = event.isRSVPEvent

  return (
    <Card className={styles.OrderItemCard}>
      <div className={styles.OrderItemContent}>
        {orgScopeAttendeePage === null ? (
          <PoshImage src={accountAvatar} alt='Account Avatar' className={styles.OrderItemAvatar} />
        ) : (
          <Link to={orgScopeAttendeePage}>
            <PoshImage src={accountAvatar} alt='Account Avatar' className={styles.OrderItemAvatar} />
          </Link>
        )}
        <div className={styles.OrderItemDetails}>
          <Text size='medium' className={styles.OrderItemAccountName} bold>
            {orgScopeAttendeePage === null ? accountName : <Link to={orgScopeAttendeePage}>{accountName}</Link>}
          </Text>
          <Text size='small'>{moment(orderDate).format('MMMM D, YYYY h:mm A')}</Text>
          <Text size='small' className={styles.OrderItemEventName} bold>
            <Link to={`/e/${url}`}>{eventName}</Link>
          </Text>
          <div className={styles.TagsList}>
            {tags.map(tag => (
              <Tag key={tag} variant={tag} />
            ))}
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', marginLeft: 'auto', alignItems: 'flex-end'}}>
          <Text size='small' muted style={{marginLeft: 'auto'}}>
            <Link to={orgScopeOrdersPage}>{`Order #${orderNumber}`}</Link>
          </Text>
          <Text size='xl' bold style={{marginLeft: 'auto'}}>
            {isRSVPEvent ? 'RSVP' : Currency.format(orderSubtotal, orderCurrency)}
          </Text>
        </div>
      </div>
    </Card>
  )
}

const OrderItemLoading = () => {
  return (
    <Card className={styles.OrderItemCard}>
      <div className={styles.OrderItemContent}>
        <div className={classNames(styles.OrderItemAvatar, styles.loading)} />
        <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
          <Text.Loading size='medium' width={125} />
          <Text.Loading size='small' />
        </div>
      </div>
    </Card>
  )
}

OrderItem.Loading = OrderItemLoading
