import React, {useState} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'

import {locationSchema, TLocation} from '@posh/model-types'
import {WHERE_OPTIONS} from '@posh/types'
import {captureException} from '@sentry/react'
import {useGetEventPlaylist} from 'apis/Events/playlists/useGetEventPlaylist'
import {
  UnderlinedDropdown,
  UnderlinedDropdownOption,
  UnderlinedDropdownOptionType,
} from 'components/Dropdown/UnderlinedDropdown'
import {useDimensions} from 'hooks/useDimensions'
import {useUpdateLocationByIp} from 'pages/EventMarketplace/MainFilters/useUpdateLocationByIp'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import {PartyFoul} from 'pages/PageNotFound/PartyFoul/PartyFoul'

import {DEFAULT_LOCATION_PRESET, useSelectLocation} from '../../hooks/useSelectLocation'
import {EventPlaylist, EventPlaylistProps} from './EventPlaylist/EventPlaylist'

import styles from './EventPlaylistPage.module.scss'

const DARKEN_OPACITY = 0.5

const eventPlaylistBackgroundStyle = ({
  backgroundImage,
}: Pick<EventPlaylistProps, 'backgroundImage' | 'backgroundImagePalette'>): React.CSSProperties => {
  return {
    background: `linear-gradient(180deg, rgba(0, 0, 0, ${DARKEN_OPACITY}) 0%, rgba(0, 0, 0, ${DARKEN_OPACITY}) 100%), url(${backgroundImage}) lightgray`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
}

const useEventPlaylistLocationFromSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  let locationObjectFromParams = {}
  try {
    locationObjectFromParams = JSON.parse(searchParams.get('location') ?? '{}')
  } catch (e) {
    captureException(e, {extra: {searchParams}})
  }
  const locationParsed = locationSchema.safeParse(locationObjectFromParams)

  const [location, setLocation] = useState<TLocation | undefined>(
    locationParsed.success ? locationParsed.data : undefined,
  )

  const onUpdateLocationQueryParams = (location: TLocation) => {
    switch (location.type) {
      case 'preset':
        setSearchParams({location: JSON.stringify(location)})
        break
      case 'custom':
        setSearchParams({
          location: JSON.stringify({
            type: location.type,
            location: location.location,
            // Need to convert to string for URLSearchParams.
            lat: location.lat.toString(),
            long: location.long.toString(),
          }),
        })
        break
    }
  }

  const onUpdateLocationState = (location: TLocation) => {
    setLocation(location)
  }

  useUpdateLocationByIp({
    location,
    updateLocation: onUpdateLocationState,
    fallbackLocation: DEFAULT_LOCATION_PRESET,
  })

  const {onSelectLocation, isLoadingNearMeLocation} = useSelectLocation({
    onLocationSelected: location => {
      onUpdateLocationQueryParams(location)
      onUpdateLocationState(location)
    },
  })

  return {location, onSelectLocation, isLoadingNearMeLocation}
}

export const EventPlaylistPage = () => {
  const slug = useParams<{slug: string}>().slug ?? ''
  const {location, onSelectLocation, isLoadingNearMeLocation} = useEventPlaylistLocationFromSearchParams()
  const {isMobile} = useDimensions()

  const whereOptions: UnderlinedDropdownOption[] = [...WHERE_OPTIONS]
    .filter(o => o !== location?.location)
    .map(o => ({
      type: 'text' as UnderlinedDropdownOptionType,
      value: o,
    }))
  whereOptions.push({type: 'city-input'})

  const {data, isSuccess, isError} = useGetEventPlaylist(
    {slug, location},
    {keepPreviousData: true, enabled: !!location},
  )

  const backgroundStyle = isSuccess ? eventPlaylistBackgroundStyle(data.playlist) : {}

  return (
    <div className={styles.EventPlaylistPage} style={backgroundStyle}>
      {!isMobile && <PoshHeader />}
      {isSuccess && (
        <EventPlaylist
          {...data.playlist}
          locationInput={
            <>
              {!location?.location || isLoadingNearMeLocation ? (
                <div style={{display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'flex-end'}}>
                  <h4 style={{fontSize: 30}}>finding location...</h4>
                </div>
              ) : (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                  {location.location !== 'Near Me' && <h4 style={{fontSize: 30}}>in</h4>}
                  <UnderlinedDropdown
                    selectedValue={location.location}
                    options={whereOptions}
                    onSelect={onSelectLocation}
                  />
                </div>
              )}
            </>
          }
        />
      )}
      {isError && <PartyFoul />}
    </div>
  )
}
