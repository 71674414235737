import React from 'react'
import {useNavigate} from 'react-router-dom'

import {EventResponse, OrderAndEvent, OrderResponse} from 'apis/Accounts/useFetchAllAttendeeOrdersAndEvents'
import {useFetchPortalLinkWithMutation} from 'apis/Accounts/useFetchPortalLink'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import PoshInfo from 'components/form/PoshInfo'
import {PoshImage, PoshImageBackground} from 'components/PoshImage/PoshImage'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'
import every from 'lodash/every'
import moment from 'moment'
import {AddToWallet} from 'pages/ReceiptPage/AddToWallet'
import {CalendarSvg} from 'pages/ReceiptPage/SVGs/svgs'

import {PaymentStatus} from '../../../apis/Checkout'
import {ArrowRightCircleDotted, wallet} from '../../assets/Icons'

import './index.scss'

interface TicketTableProps {
  tickets: OrderResponse['tickets']
  tables: OrderResponse['tables']
  currencySymbol: string
}

const TicketTable = (props: TicketTableProps) => {
  const {tickets, tables, currencySymbol} = props
  return (
    <table className='AttendeeEventCard-ticketTable'>
      <tbody>
        {tickets?.map((ticket, i) => (
          <tr key={i}>
            <td>
              <PoshImage src='https://images.posh.vip/b2/ticket+(2).svg' />
            </td>
            <td>
              <div style={{overflow: 'hidden', height: '24px'}}>{ticket.name}</div>
            </td>
            <td>
              {currencySymbol}
              {ticket.price.toFixed(2)}
            </td>
          </tr>
        ))}
        {tables?.map((table, i) => (
          <tr key={i}>
            <td>
              <PoshImage src='https://images.posh.vip/b2/table+(3).svg' />
            </td>
            <td>{table.name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

interface DiscountTableProps {
  promoCode: OrderResponse['promoCode']
  discount: OrderResponse['discount']
  currencySymbol: string
}

const DiscountTable = (props: DiscountTableProps) => {
  const {promoCode, discount, currencySymbol} = props
  return (
    <table className='AttendeeEventCard-discountTable'>
      <tbody>
        <tr>
          <td>
            <PoshImage src='https://images.posh.vip/b2/tag+(1).svg' />
          </td>
          <td>{promoCode}</td>
          <td>
            - {currencySymbol}
            {discount?.toFixed(2)}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

interface FeeTableProps {
  feeBreakdown: OrderResponse['feeBreakdown']
  currencySymbol: string
}

const FeeTable = (props: FeeTableProps) => {
  const {feeBreakdown, currencySymbol} = props
  return (
    <table className='AttendeeEventCard-feeTable'>
      <tbody>
        {feeBreakdown?.map((fee, i) => (
          <tr key={i}>
            <td>{fee.name}</td>
            <td>
              {currencySymbol}
              {fee.totalFee.toFixed(2)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

interface OrderActionProps {
  order: Pick<OrderResponse, 'isComplimentaryOrder' | 'cartId' | '_id' | 'refunded' | 'paymentPlan'>
  event: EventResponse
  onAddToCalendar: () => void
  ticketTransfersEnabled: boolean
}

const OrderActions = (props: OrderActionProps) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const {order, event, onAddToCalendar, ticketTransfersEnabled} = props
  const navigate = useNavigate()
  const {isComplimentaryOrder, cartId, _id: orderId, refunded, paymentPlan} = order
  const hasPaymentPlans = !!paymentPlan?.length

  const handleOnClickOpenOrderQr = () => {
    if (isComplimentaryOrder) navigate(`/comp-order/${orderId}`)
    else navigate(`/receipt/${cartId?.toString()}`)
  }
  const handleOnClickViewEventDetails = () => navigate(`/e/${event.url}`)

  const shouldShowTicketTransfer =
    !isComplimentaryOrder &&
    !hasPaymentPlans &&
    !refunded &&
    moment(event.endUtc).isAfter(moment().utc()) &&
    ticketTransfersEnabled

  return (
    <div className='AttendeeEvent-order-actions'>
      {!refunded && (
        <a
          className='AttendeeEvent-order-link'
          onClick={() => {
            trackMixpanelEvent('Open QR Icon-  Orders Page')
            handleOnClickOpenOrderQr()
          }}>
          <PoshImage className='AttendeeEvent-order-icon' src='https://images.posh.vip/b2/qr-code-scan-gold.svg' />
        </a>
      )}
      <a
        className='AttendeeEvent-order-link'
        onClick={() => {
          trackMixpanelEvent('Information Icon-  Orders Page')
          handleOnClickViewEventDetails()
        }}>
        <PoshImage className='AttendeeEvent-order-icon' src='https://images.posh.vip/b2/info-gold.svg' />
      </a>
      {!refunded && (
        <>
          <a className='AttendeeEvent-order-link'>
            <AddToWallet
              image={wallet}
              orderId={orderId}
              mixpanelCallback={() => {
                trackMixpanelEvent('Wallet Icon-  Orders Page')
              }}
            />
          </a>
          <a
            className='AttendeeEvent-order-link'
            onClick={() => {
              trackMixpanelEvent('Calendar Icon-  Orders Page')
              onAddToCalendar()
            }}>
            <CalendarSvg accentColor={'#ffcc00'} />
          </a>
        </>
      )}
      {shouldShowTicketTransfer && (
        <a
          className='AttendeeEvent-order-link'
          onClick={() => {
            trackMixpanelEvent('Transfer Icon-  Orders Page')
            navigate(`/orders/transfer-tickets/${orderId}`)
          }}>
          <ArrowRightCircleDotted className='AttendeeEvent-order-icon' />
        </a>
      )}
    </div>
  )
}

const statusBadge = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.PAID:
      return 'https://images.posh.vip/b2/installment-paid.svg'
    case PaymentStatus.SCHEDULED:
      return 'https://images.posh.vip/b2/installment-scheduled.svg'
    case PaymentStatus.REFUNDED:
      return 'https://images.posh.vip/psh-assets/icons/cashback.png'
    default:
      return 'https://images.posh.vip/b2/installment-scheduled.svg'
  }
}

interface FinancingSectionProps {
  paymentPlan: OrderResponse['paymentPlan']
  currencySymbol: string
}

const FinancingSection = (props: FinancingSectionProps) => {
  const {paymentPlan, currencySymbol} = props
  const {mutateAsync: updatePaymentPlanLink, isLoading} = useFetchPortalLinkWithMutation()
  const {userId} = useSessionContext()
  const {showToast} = useToast()
  const handleFetchPortalLink = async () => {
    if (!userId) return
    try {
      const link = await updatePaymentPlanLink({userId})
      window.location.href = link
    } catch (err) {
      if (err.response) showToast({type: 'error', title: err.response.data.error})
      else showToast({type: 'error', title: 'There was a problem with your request. Please try again later.'})
    }
  }

  return (
    <div className='AttendeeEventCard-section'>
      <hr />
      <h5>Financing</h5>
      <table className='AttendeeEventCard-ticketTable paymentTable'>
        <tbody>
          {paymentPlan!.map((installment, i) => (
            <tr key={i}>
              <td>{moment(installment.date).format('l')}</td>
              <td>
                {currencySymbol}
                {installment.amount.toFixed(2)}
              </td>
              <td>
                <PoshImage src={statusBadge(installment.status)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      <Button className='goldOutline' disabled={isLoading} onClick={handleFetchPortalLink}>
        Change Payment Method
      </Button>
    </div>
  )
}

export const AttendeeEvent = ({
  orderAndEvent,
  onAddToCalendar,
  ticketTransfersEnabled,
}: {
  orderAndEvent: OrderAndEvent
  onAddToCalendar: () => void
  ticketTransfersEnabled: boolean
}) => {
  const {order, event, currencySymbol} = orderAndEvent
  const {
    tickets,
    tables,
    feeBreakdown,
    promoCode,
    discount,
    refunded,
    paymentPlan,
    partialRefund,
    total,
    feesRefunded,
  } = order
  const isFullyFinanced = paymentPlan ? every(paymentPlan, pp => pp.status == PaymentStatus.PAID) : true

  return (
    <div className='AttendeeEventCard'>
      <PoshImageBackground className='AttendeeEventCard-bg' style={{backgroundImage: `url(${event.flyer})`}} />
      <div className='AttendeeEventCard-filter' />

      <div className='AttendeeEventCard-section'>
        <span className='AttendeeEventCard-eventName'>{event.name}</span>
        <h3>Order #{order.number}</h3>
        <TicketTable tickets={tickets} tables={tables} currencySymbol={currencySymbol} />
        {!!order.discount && order.discount > 0 && (
          <DiscountTable promoCode={promoCode} discount={discount} currencySymbol={currencySymbol} />
        )}
        <FeeTable feeBreakdown={feeBreakdown} currencySymbol={currencySymbol} />
      </div>

      {isFullyFinanced ? (
        <div className='AttendeeEventCard-section'>
          <h5>
            {currencySymbol}
            {total}
            {refunded && (
              <>
                {' – Refunded'}
                {!feesRefunded && <PoshInfo info='Processing fees are not refunded.' />}
              </>
            )}
            {partialRefund && (
              <>
                {` – ${currencySymbol}${partialRefund.toFixed(2)} Refunded`}
                {!feesRefunded && <PoshInfo info='Processing fees are not refunded.' />}
              </>
            )}
          </h5>
          <hr />
          <OrderActions
            order={order}
            event={event}
            onAddToCalendar={onAddToCalendar}
            ticketTransfersEnabled={ticketTransfersEnabled}
          />
        </div>
      ) : (
        <FinancingSection paymentPlan={paymentPlan} currencySymbol={currencySymbol} />
      )}
    </div>
  )
}
export default AttendeeEvent
