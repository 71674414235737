export enum PoshRoutes {
  HOME = '/',
  REDIRECT = '/redirect',
  LOGIN = '/login',
  MAGIC = '/magic/:token',
  SIGN_UP = '/signup',
  OWNER = '/owner',
  AFFILIATION_OVERVIEW = '/dashboard/affiliation/:affiliateId',
  DASHBOARD = '/dashboard/*',
  PROFILE = '/p/:username',
  CAMPAIGN_LINK = '/cpn/:campaignShortUrl',
  SHORT_EVENT_URL = '/f/:hexUrl',
  GROUP = '/g/:url',
  VENUE = '/v/:url',
  CHECKOUT = '/e/:eventUrl/checkout',
  PHOTOS = '/e/:eventUrl/photos',
  EVENT = '/e/:eventUrl',
  EVENTTOS = '/e/:eventUrl/tos',
  RECEIPT = '/receipt/:cartId',
  COMP_RECEIPT = '/comp-order/:orderId',
  DISABLED_RECEIPT_PAGE = '/confirmation/:eventUrl',
  PASSWORD = '/reset-password/*',
  ORDER_QR_CODE = '/view-ticket-qr/:orderId',
  CREATE_GROUP = '/create_group',
  TEAM = '/team',
  FAQ = '/faq',
  TOS = '/tos',
  PRIVACY = '/privacy',
  VERIFY_EMAIL = '/verify-email/:accountId/:emailVerificationToken',
  ADMISSIONS = '/admissions',
  RATE_LIMIT_EXCEEDED = '/rate-limit-exceeded',
  COMMUNITY_ROUTES = '/community/*',
  COMMUNITY_PROFILE = '/c/:communityCategory/:communityUrl',
  ADMIN_ROUTES = '/admin/*',
  EXPLORE = '/explore',
  CONFIRM_ACCOUNT = '/confirm-account/:accountId/',
  CREATE = '/create/*',
  MAILCHIMP_OAUTH = '/mailchimp-oauth',
  MOBILE = '/app',
  EVENT_PLAYLIST = '/c/:slug',
  EVENT_ROUTES = '/:domain/groups/:groupId/events/:eventId/*',
  GROUP_ROUTES = '/:domain/groups/:groupId/*',
  MANAGE_EVENT_PLAYLIST_ROUTES = '/manage-event-playlists/*',
  TICKET_TRANSFER_ROUTES = '/orders/*',
}
