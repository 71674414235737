import React, {useState} from 'react'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import {EventResponse, useFetchAllAttendeeOrdersAndEvents} from 'apis/Accounts/useFetchAllAttendeeOrdersAndEvents'
import AttendeeEvent from 'components/data/AttendeeEvent'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {MobileAppExclusiveFeatureOverlay} from 'components/MobileAppExclusiveFeatureOverlay'
import {useFeatureGate} from 'hooks/useFeatureGate'
import AddToCalendarModal from 'pages/EventPage/AddToCalendarModal'

import {useAccountOrdersPageParams} from './useAccountOrdersPageParams'

const AccountOrders = () => {
  const {data: ordersAndEvents, isFetching} = useFetchAllAttendeeOrdersAndEvents()
  const ticketTransfersEnabled = useFeatureGate('send_ticket_transfers')
  const [eventForCalendar, setEventForCalendar] = useState<EventResponse | undefined>(undefined)
  const {transfer} = useAccountOrdersPageParams()

  const setAddCalendarModalOpen = (open: boolean) => {
    if (!open) setEventForCalendar(undefined)
  }

  if (transfer)
    return (
      <MobileAppExclusiveFeatureOverlay
        image={'https://images.posh.vip/ticket-transfer-assets/ticket-transfer-mockup.png'}
        title={'Install the app to accept and use your tickets'}
      />
    )

  return (
    <div className='GroupsDash'>
      <div className='GroupsDash-inner'>
        <h2>My Orders</h2>
        {ordersAndEvents && ordersAndEvents.length > 0 ? (
          <div className='OrdersCont'>
            {ordersAndEvents.map(orderAndEvent => (
              <AttendeeEvent
                key={orderAndEvent.order._id}
                orderAndEvent={orderAndEvent}
                onAddToCalendar={() => setEventForCalendar(orderAndEvent.event)}
                ticketTransfersEnabled={ticketTransfersEnabled}
              />
            ))}
          </div>
        ) : (
          <>
            {isFetching ? <SpinLoader height={80} /> : <p style={{color: 'grey'}}>You have no tickets to any events</p>}
          </>
        )}
        {eventForCalendar && (
          <AddToCalendarModal
            event={eventForCalendar}
            addCalendarModalOpen={eventForCalendar !== undefined}
            setAddCalendarModalOpen={setAddCalendarModalOpen}
          />
        )}
      </div>
    </div>
  )
}

export default AccountOrders
