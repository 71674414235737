import React, {ChangeEvent, useState} from 'react'

import {useQueryClient} from '@tanstack/react-query'
import {EventModel, LocationData} from 'apis/Events/types'
import {DuplicateEventInput, useDuplicateEvent} from 'apis/Events/useDuplicateEvent'
import Button from 'components/form/Button'
import {PictureState} from 'components/form/ImagePicker'
import {ImageSearchModal} from 'components/form/ImageSearchModal'
import Input from 'components/form/Input'
import PoshDatePicker from 'components/form/PoshDatePicker'
import PoshLocationInput from 'components/form/PoshLocationInput'
import PoshStyledModal, {PoshStyledModalProps} from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'
import {useUploadImage} from 'hooks/uploadImage/useUploadImage'
import {useDimensions} from 'hooks/useDimensions'
import {FlyerPicker} from 'pages/CreateEventDeprecated/CreateEventHandler/CreateEventVisuals/flyerPicker'

import {duplicateEventValidator} from './duplicateEventValidator'

import './styles.scss'

interface DuplicateEventsModalProps extends PoshStyledModalProps {
  event: EventModel
}

const DuplicateEventModal = (props: DuplicateEventsModalProps) => {
  const {event, onClose} = props
  const {showToast} = useToast()
  const queryClient = useQueryClient()
  const {isMobile} = useDimensions()

  const [venue, setVenue] = useState<LocationData>({
    address: event.venue.address ?? '',
    location: event.location,
  })

  const {uploadImage, isUploading} = useUploadImage({
    imageType: 'event-flyer',
    onSuccess: (imageId, imageUrl) => {
      setDuplicateEventAttrs(prevDuplicateEventAttrs => {
        return {
          ...prevDuplicateEventAttrs,
          flyerImageId: imageId,
          flyer: imageUrl,
          // smallFlyer: imageUrl,
        }
      })
    },
  })

  const [isImageSearchModalOpen, setIsImageSearchModalOpen] = useState(false)
  const [duplicateEventAttrs, setDuplicateEventAttrs] = useState<Omit<DuplicateEventInput, 'eventId'>>({
    name: event.name,
    start: event.start,
    end: event.end,
    startUtc: event.startUtc,
    endUtc: event.endUtc,
    timezone: event.timezone,
    flyer: event.flyer,
    flyerImageId: event.flyerImageId,
    smallFlyer: event.smallFlyer,
    venueName: event.venue.name,
  })

  const onImageChange = (img: PictureState) => {
    if (img.file) return uploadImage(img.file)
    else
      return setDuplicateEventAttrs(prevDuplicateEventAttrs => ({
        ...prevDuplicateEventAttrs,
        flyer: img.url!,
        smallFlyer: img.url!,
        flyerImageId: undefined,
      }))
  }

  const {mutateAsync: duplicateEvent, isLoading} = useDuplicateEvent({
    onSuccess: async ({message}) => {
      await queryClient.invalidateQueries(['groupEvents'])
      showToast({type: 'success', title: message})
      onClose()
    },
    onError: error => {
      showToast({type: 'error', title: 'There was an error duplicating your event.', subtitle: error.message})
    },
  })

  const onDuplicate = async () => {
    try {
      await duplicateEventValidator.validate(duplicateEventAttrs)
      duplicateEventAttrs.address = venue.address
      duplicateEventAttrs.location = venue.location
      await duplicateEvent({...duplicateEventAttrs, eventId: event._id})
    } catch (error) {
      if (error.response) return
      showToast({type: 'error', title: 'There was an error duplicating your event.', subtitle: error.message})
    }
  }

  return (
    <PoshStyledModal {...props}>
      <div className='DuplicateEventModal'>
        <h3>Duplicate Event</h3>
        {!isMobile && (
          <p className='center noMargin'>
            Duplicating an event copies over all it&#39;s settings, tickets, and other attributes. You can changes them
            later in the event settings.
          </p>
        )}
        <Input
          placeholder='Event Name'
          className='large fullWidth'
          value={duplicateEventAttrs.name ?? event?.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setDuplicateEventAttrs({...duplicateEventAttrs, name: e.target.value})
          }}
        />
        <div className='DuplicateEventModal-mid'>
          <div className='DuplicateEventModal-row'>
            <PoshDatePicker
              label='Event Start:'
              className='column'
              value={duplicateEventAttrs.startUtc.toString()}
              setDates={(localDate, utcDate) =>
                setDuplicateEventAttrs({
                  ...duplicateEventAttrs,
                  start: localDate ? new Date(localDate) : duplicateEventAttrs.start,
                  startUtc: utcDate ? new Date(utcDate) : duplicateEventAttrs.startUtc,
                })
              }
              timezone={duplicateEventAttrs.timezone ?? 'America/New_York'}
            />
            <PoshDatePicker
              label='Event End:'
              className='column'
              value={duplicateEventAttrs.endUtc.toString()}
              setDates={(localDate, utcDate) =>
                setDuplicateEventAttrs({
                  ...duplicateEventAttrs,
                  end: localDate ? new Date(localDate) : duplicateEventAttrs.end,
                  endUtc: utcDate ? new Date(utcDate) : duplicateEventAttrs.endUtc,
                })
              }
              timezone={duplicateEventAttrs.timezone ?? 'America/New_York'}
            />
            <Input
              label='Venue Name:'
              className='fullWidth column'
              placeholder='Enter a venue name'
              value={duplicateEventAttrs.venueName ?? ''}
              onChange={e => setDuplicateEventAttrs({...duplicateEventAttrs, venueName: e.target.value})}
            />
            <PoshLocationInput
              label='Event Location:'
              className='column'
              inputClassName='fullWidth'
              placeholder='Enter a location'
              address={venue.address}
              onPlaceSelected={place => setVenue(place)}
            />
          </div>
          {!isMobile && (
            <FlyerPicker
              title='Flyer:'
              image={duplicateEventAttrs.flyer}
              openModal={() => setIsImageSearchModalOpen(true)}
            />
          )}
        </div>
        <Button disabled={isLoading || isUploading} isLoading={isLoading} onClick={onDuplicate}>
          Duplicate Event
        </Button>
      </div>
      {!isMobile && (
        <ImageSearchModal
          setFile={onImageChange}
          isOpen={isImageSearchModalOpen}
          onClose={() => setIsImageSearchModalOpen(false)}
          noborder
          width={780}
        />
      )}
    </PoshStyledModal>
  )
}

export default DuplicateEventModal
