import React from 'react'
import {useParams} from 'react-router-dom'

import {zodResolver} from '@hookform/resolvers/zod'
import {CurrencyCode} from '@posh/types'
import {Ticket} from 'apis/Events/types'
import {useUpdateEvent_DEPRECATED} from 'apis/Events/useUpdateEventDEPRECATED'
import {useUpdateTicket} from 'apis/Events/useUpdateTicket'
import useFetchOrderForTickets from 'apis/Orders/useFetchOrderForTickets'
import {useToast} from 'components/toasts/ToastProvider'
import {isEmpty} from 'lodash'
import SaveDiscardBottomBar from 'pages/EventManagementPages/SaveDiscardBottomBar'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'
import {useResourcePageParams} from 'pages/PoshAppLayout'
import {getCurrencySymbol} from 'pages/Util/getCurrencySymbol'

import TicketOrdersTable from '../TicketOrdersTable'
import {TicketSettings} from '../TicketSettings'
import {useRefetchAndGoBack} from '../TicketSettings/helpers'
import {UpdateTicketFormSchema} from '../TicketSettings/ticketSettingsForm/schema'
import {transformDefaultValues} from '../TicketSettings/ticketSettingsForm/transformDefaultValues'
import {useTicketSettingsForm} from '../TicketSettings/ticketSettingsForm/useTicketSettingsForm'
import {DeleteTicketButton} from './DeleteButton'

interface EditTicketProps {
  tickets: Ticket[]
  timezone: string
  currency: CurrencyCode
}

const EditTicket = (props: EditTicketProps) => {
  const {tickets, timezone, currency} = props
  const {eventId, groupId} = useResourcePageParams()
  const {ticketId} = useParams<{ticketId: string}>()
  const ticket = tickets.find(t => t.id === ticketId)

  const {
    data: ordersAndNames,
    refetch: refetchOrdersForTicket,
    isFetching: isFetchingOrders,
    isError: isErrorFetchingOrders,
    dataUpdatedAt,
  } = useFetchOrderForTickets({
    ticketId: ticketId!,
    eventId: eventId!,
  })

  const {mutateAsync: updateEvent, isLoading: isDeletingTicket} = useUpdateEvent_DEPRECATED()
  const {mutateAsync: updateTicket} = useUpdateTicket()
  const {refetchAndGoBack} = useRefetchAndGoBack(eventId!)

  const {showToast} = useToast()

  const currencySymbol = getCurrencySymbol(currency)

  const form = useTicketSettingsForm({
    defaultValues: transformDefaultValues(ticket),
    resolver: zodResolver(UpdateTicketFormSchema),
  })

  const onDeleteTicket = async () => {
    try {
      if (!eventId || !groupId) throw new Error('Event or group not found')
      if (!isEmpty(ordersAndNames)) throw new Error('Cannot delete a ticket with orders')
      const updatedTickets = tickets.filter(t => t.id !== ticketId)
      await updateEvent({eventId, groupId, eventData: {tickets: updatedTickets}})
      showToast({type: 'success', title: 'Ticket deleted!'})
      await refetchAndGoBack()
    } catch (error) {
      if (error.response) showToast({type: 'error', title: error.response.data.error})
      else showToast({type: 'error', title: error.message})
    }
  }

  const onSave = form.handleSubmit(
    async ticket => {
      if (!eventId || !groupId) throw new Error('Event or group not found')
      try {
        const msg = await updateTicket({eventId, ticket})
        showToast({type: 'success', title: msg})
        await refetchAndGoBack()
      } catch (error) {
        showToast({
          type: 'error',
          title: 'There was an error updating the ticket.',
          subtitle: error.response.data.error,
        })
      }
    },
    (err: any) => {
      showToast({type: 'error', title: err.message || `Some fields are invalid: ${Object.keys(err).join(', ')}`})
    },
  )

  const shouldRenderTicketOrdersTable = !!ticket
  return (
    <>
      <BackAndTitle />
      <div className='TicketEditor'>
        <TicketSettings form={form} isEditing={true} timezone={timezone} currencySymbol={currencySymbol} />
        <DeleteTicketButton
          onDeleteTicket={onDeleteTicket}
          isDeletingTicket={isDeletingTicket}
          ordersAndNames={ordersAndNames}
          isFetchingOrders={isFetchingOrders}
          isErrorFetchingOrders={isErrorFetchingOrders}
        />
      </div>
      {shouldRenderTicketOrdersTable && (
        <TicketOrdersTable
          ticket={ticket}
          dataUpdatedAt={dataUpdatedAt}
          ordersAndNames={ordersAndNames}
          onRefresh={refetchOrdersForTicket}
        />
      )}
      <SaveDiscardBottomBar isOpen={form.formState.isDirty} onSave={onSave} onDiscard={refetchAndGoBack} />
    </>
  )
}

export default EditTicket
