import React from 'react'
import {useNavigate} from 'react-router-dom'

import {useCreateClikPhotographerBooking} from 'apis/Services/useCreateClikPhotographerBooking'
import {useCreatePendingClikPhotographerBooking} from 'apis/Services/useCreatePendingClikPhotographerBooking'
import ConcertCrowd from 'assets/images/concert-crowd.png'
import PhotographersMap from 'assets/images/photographer-map.png'
import {Counter} from 'components/Counter/Counter'
import Button from 'components/form/Button'
import PoshDatePicker from 'components/form/PoshDatePicker'
import {useToast} from 'components/toasts/ToastProvider'
import {trpc} from 'lib/trpc'

import {Stage} from '../StagedUserFlow/Stage'
import {useStagedUserFlow} from '../StagedUserFlow/StagedUserFlowContext'
import {usePhotographyService} from './PhotographyServiceFlow'

export const StageOne = () => {
  const {nextStage} = useStagedUserFlow()

  return (
    <Stage image={ConcertCrowd}>
      <div>
        <h4>Let&apos;s find your perfect event photographer...</h4>
        <p>
          We already have your event details. Answer a few quick questions so we can find exactly what you&apos;re
          looking for.
        </p>
        <Button className='continue' onClick={nextStage}>
          Continue →
        </Button>
      </div>
    </Stage>
  )
}

export const StageTwo = () => {
  const {nextStage} = useStagedUserFlow()
  const {value, setValue} = usePhotographyService()

  return (
    <Stage image={ConcertCrowd}>
      <div>
        <p>What time would you like the photographer to show up?</p>
        <PoshDatePicker
          timezone='America/New_York'
          value={value.time?.toString()}
          setDates={(_localDate, utcDate) =>
            setValue(prev => ({...prev, time: utcDate ? new Date(utcDate) : prev.time}))
          }
        />
        <p>How long should they stay? (in hours)</p>
        <Counter
          value={value.lengthOfStay}
          onChange={value => setValue(prev => ({...prev, lengthOfStay: value}))}
          min={2}
          max={8}
          step={0.5}
        />
        <Button onClick={nextStage} style={{marginTop: 20}}>
          Continue →
        </Button>
      </div>
    </Stage>
  )
}

export const StageThree = () => {
  const queryClient = trpc.useContext()
  const {showToast} = useToast()
  const {previousStage, nextStage} = useStagedUserFlow()
  const {
    value: {time, lengthOfStay, pending, eventId, groupId},
  } = usePhotographyService()
  const {mutate: createClikBooking, isLoading: isCreateClikBookingLoading} = useCreateClikPhotographerBooking({
    onSuccess: () => {
      queryClient.services.getServiceBookings.invalidate()
      nextStage()
    },
    onError: error => {
      showToast({type: 'error', title: 'Something went wrong trying to create your booking', subtitle: error.message})
    },
  })
  const {mutate: createPendingClikBooking, isLoading: isCreatePendingClikBookingLoading} =
    useCreatePendingClikPhotographerBooking({
      onSuccess: () => {
        nextStage()
      },
      onError: error => {
        showToast({type: 'error', title: 'Something went wrong trying to create your booking', subtitle: error.message})
      },
    })

  const isLoading = isCreateClikBookingLoading || isCreatePendingClikBookingLoading

  const onSubmit = () => {
    if (pending) {
      createPendingClikBooking({
        groupId,
        pendingEventId: eventId,
        numHours: lengthOfStay,
        photographerStartTime: time.toJSON(),
      })
    } else {
      createClikBooking({eventId, numHours: lengthOfStay, photographerStartTime: time.toJSON()})
    }
  }

  return (
    <Stage image={ConcertCrowd}>
      <div>
        <h4>Confirm your booking request details</h4>
        <p>
          <b>Arrival: </b> {time.toLocaleString()}
        </p>
        <p>
          <b>Length of Stay: </b> {lengthOfStay} hours
        </p>
        <Button onClick={previousStage} style={{marginBottom: 15}}>
          ← Go Back
        </Button>
        <Button onClick={onSubmit} isLoading={isLoading}>
          Confirm Your Request
        </Button>
      </div>
    </Stage>
  )
}

export const StageFour = () => {
  const navigate = useNavigate()
  const {
    value: {pending},
  } = usePhotographyService()
  return (
    <Stage image={PhotographersMap} greyscale={false}>
      <div>
        <h4>We&apos;re notifying the best local photographers!</h4>
        <p>
          We partner with <b>Clik</b> to provide the best professional photographers in the area.
        </p>
        {pending ? (
          <p>Once your event goes live, you&apos;ll be contacted by Clik within 24 hours with additional details.</p>
        ) : (
          <p>You&apos;ll be contacted by Clik within 24 hours with additional details.</p>
        )}

        <Button onClick={() => navigate(-1)}>Return to {pending ? 'launch your event!' : 'Dashboard'}</Button>
      </div>
    </Stage>
  )
}
