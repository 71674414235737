import React from 'react'

import {EventResponse} from 'apis/Accounts/useFetchAllAttendeeOrdersAndEvents'
import {EventPublic} from 'apis/Events/types'
import {useMixpanel} from 'apis/MixPanelHandler'
import Button from 'components/form/Button'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {downloadCalendar} from 'helpers/downloadCalendarEvent'

import {CalendarHelpers} from './helpers'

const AddToCalendarModal = ({
  event,
  addCalendarModalOpen,
  setAddCalendarModalOpen,
}: {
  event: EventPublic | EventResponse
  addCalendarModalOpen: boolean
  setAddCalendarModalOpen: (value: boolean) => void
}) => {
  const {trackEvent: trackMixpanelEvent} = useMixpanel()

  const eventCalendarLinkParams = CalendarHelpers.getEventCalendarLinkParams(event)
  if (!eventCalendarLinkParams) {
    addCalendarModalOpen && alert("There's an issue with this event. Please try again or contact support.")
    return null
  }

  const googleCalendarShareLink = CalendarHelpers.calendarLink.google(eventCalendarLinkParams)
  const outlookCalendarShareLink = CalendarHelpers.calendarLink.outlook(eventCalendarLinkParams)

  return (
    <PoshStyledModal isOpen={addCalendarModalOpen} onClose={() => setAddCalendarModalOpen(false)} center noborder>
      <h3>Add to Calendar</h3>
      <div className='buttonWrapper column' style={{padding: '20px 0'}}>
        <a
          href={googleCalendarShareLink}
          onClick={() => {
            trackMixpanelEvent('Google Calendar-  Add to calendar modal')
          }}
          target='_blank'
          rel='noreferrer'>
          <Button>Google Calendar</Button>
        </a>
        <a
          onClick={() => {
            trackMixpanelEvent('Apple Calendar -  Add to calendar modal')
            downloadCalendar(event)
          }}
          target='_blank'
          rel='noreferrer'>
          <Button>Apple Calendar</Button>
        </a>
        <a
          href={outlookCalendarShareLink}
          onClick={() => {
            trackMixpanelEvent('Outlook Calendar -  Add to calendar modal')
          }}
          target='_blank'
          rel='noreferrer'>
          <Button>Outlook Calendar</Button>
        </a>
      </div>
    </PoshStyledModal>
  )
}

export default AddToCalendarModal
