import React from 'react'

import {Card} from 'components/Card'

const TransferOrderCard = () => {
  return (
    <Card title={'Sent via ticket transfer'} animationIndex={1} className='OrderPage-ticketTransfer'>
      <p>This order was sent to the attendee via ticket transfer.</p>
    </Card>
  )
}

export default TransferOrderCard
