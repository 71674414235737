import React, {useState} from 'react'
import PhoneInput from 'react-phone-number-input'

import useSendVerificationCode, {useCheckVerificationCodeAndUpdateAccount} from 'apis/Accounts/useVerifyNewLoginMethod'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import {useToast} from 'components/toasts/ToastProvider'
import useSessionContext from 'domains/Auth/SessionContext'

const GeneralSettings = () => {
  const {showToast} = useToast()
  const {currentUser, setCurrentUser, userId} = useSessionContext()

  const {mutateAsync: sendVerificationCode, isLoading: isSendingVerificationCode} = useSendVerificationCode()
  const {mutateAsync: checkVerificationCodeAndUpdateAccount, isLoading: isVerifyingCode} =
    useCheckVerificationCodeAndUpdateAccount()

  const [email, setEmail] = useState(currentUser?.email)
  const [phone, setPhone] = useState(currentUser?.phone ?? '')
  const [hasSentVerification, setHasSentVerification] = useState(false)
  const [token, setToken] = useState('')

  if (!currentUser || !userId) return null

  const methodBeingChanged = () => {
    if (email !== currentUser.email && phone === currentUser.phone) {
      return 'Email'
    } else if (phone !== currentUser.phone && email === currentUser.email) {
      return 'Phone'
    } else {
      return null
    }
  }

  const updateLocalStorage = () => {
    const storageString = localStorage.getItem('currentUser') as string
    const storageObject = JSON.parse(storageString)
    if (methodBeingChanged() === 'Email') storageObject['email'] = email
    else if (methodBeingChanged() === 'Phone') storageObject['phone'] = phone
    localStorage.setItem('currentUser', JSON.stringify(storageObject))
    setCurrentUser(storageObject)
  }

  const sendVerificationToken = async () => {
    const method = methodBeingChanged()
    if (method === null) {
      showToast({type: 'error', title: 'Please change at least one of the fields to to update your profile.'})
      return
    }
    try {
      const newLoginMethod = methodBeingChanged() === 'Email' ? email : phone
      if (!newLoginMethod) {
        showToast({type: 'error', title: 'Please enter a valid email or phone number.'})
        return
      }
      const res = await sendVerificationCode({newLoginMethod, loginMethod: method, userId})
      showToast({type: 'success', title: res})
      setHasSentVerification(true)
      return
    } catch (err) {
      showToast({type: 'error', title: err.response.data.error})
      return
    }
  }

  const verifyToken = async () => {
    const method = methodBeingChanged()
    if (method === null) {
      showToast({type: 'error', title: 'Please change at least one of the fields to to update your profile.'})
      return
    }
    try {
      const newLoginMethod = methodBeingChanged() === 'Email' ? email : phone
      if (!newLoginMethod) {
        showToast({type: 'error', title: 'Please enter a valid email or phone number.'})
        return
      }
      const res = await checkVerificationCodeAndUpdateAccount({
        token,
        userId,
        loginMethod: method,
        newLoginMethod: newLoginMethod,
      })
      showToast({type: 'success', title: res})
      setHasSentVerification(false)
      updateLocalStorage()
      return
    } catch (err) {
      showToast({type: 'error', title: err.response.data.error})
      return
    }
  }

  const resetValues = () => {
    setEmail(currentUser.email)
    setPhone(currentUser.phone ?? '')
    setHasSentVerification(false)
  }

  const isDirty = email !== currentUser.email || phone !== currentUser.phone

  return (
    <div className='AccountSettings-wrapper'>
      {hasSentVerification ? (
        <>
          <label>Verification Code</label>
          <Input
            type='text'
            value={token ?? ''}
            onChange={e => {
              setToken(e.target.value)
            }}
          />
        </>
      ) : (
        <>
          <label>Email</label>
          <Input
            type='text'
            value={email ?? ''}
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
          <label>Phone</label>
          <PhoneInput
            className='AccountSettings-phoneInput'
            placeholder='Phone Number'
            defaultCountry={currentUser?.billingCountry ?? 'US'}
            value={phone}
            onChange={e => {
              if (e) setPhone(e)
            }}
          />
        </>
      )}

      <div className='buttonWrapper'>
        {isDirty && (
          <Button className='dark light' onClick={() => resetValues()}>
            Cancel
          </Button>
        )}
        {hasSentVerification ? (
          <Button className='gold light' isLoading={isVerifyingCode} onClick={() => verifyToken()}>
            {`Verify ${methodBeingChanged()}`}
          </Button>
        ) : (
          <>
            {methodBeingChanged() !== null && (
              <Button
                className='gold light'
                isLoading={isSendingVerificationCode}
                onClick={() => sendVerificationToken()}>
                {`Change ${methodBeingChanged()}`}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default GeneralSettings
