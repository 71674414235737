import React from 'react'
import {Helmet} from 'react-helmet'
import {useParams} from 'react-router-dom'

import {getGroupAvi} from '@posh/model-types'
import {useIsCurrentUserFollowingGroup} from 'apis/Social/accounts/useListGroupsFollowing'
import useCheckGroupUrl from 'apis/Util/useCheckGroupUrl'
import moment from 'moment-timezone'
import PoshHeader from 'pages/OwnerPage/PoshHeader'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import PoshLoaderTransition from 'pages/PoshLoaderTransition'
import PoshMobileNav from 'pages/PoshMobileNav'
import Profile from 'pages/Profile'
import {calculateEventStatus} from 'pages/Util/timezoneHelper'

import './GroupProfile.scss'

const GroupProfile = () => {
  const {url} = useParams<{url: string}>()

  const {data, refetch, isFetched, isFetching} = useCheckGroupUrl(url ?? '')

  if (!isFetched) refetch()

  const group = data?.group
  const events = data?.events.filter(e => e.displayOnThirdPartySites) ?? []
  const {isCurrentUserFollowingGroup} = useIsCurrentUserFollowingGroup(group?._id)

  if (!group && isFetched) return <PageNotFound />

  const upcomingEvents = events.filter(e => {
    const status = calculateEventStatus(moment.tz(e.end, e.timezone))
    return status == 'live'
  })

  const pastEvents = events
    .filter(e => {
      const status = calculateEventStatus(moment.tz(e.end, e.timezone))
      return status == 'ended'
    })
    .reverse()
    .slice(0, 12)

  if (!group) return <PoshLoaderTransition pageIsChanging={true} fadeOutTransition={true} />

  return (
    <>
      <Helmet>
        <title>{`${group?.name}`}</title>
        {/*<meta name="description" content="">*/}
        <meta property='og:title' content={group.name} />
        <meta property='og:description' content={group.name} />
        <meta property='og:image' content={getGroupAvi(group)} />
        <meta property='og:url' content={`https://posh.vip/e/${group?.url}`} />
        <meta property='og:type' content='website' />
        <meta property='twitter:title' content={group.name} />
        <meta property='twitter:description' content={group.name} />
        <meta property='twitter:image' itemProp='image' content={getGroupAvi(group)} />
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content='posh-react.herokuapp.com' />
      </Helmet>

      <PoshHeader isTranslucent desktopOnly />
      <div className='PProfileWrapper'>
        <Profile
          _id={group._id}
          isFetching={isFetching}
          type='organization'
          avi={getGroupAvi(group)}
          title={group?.name}
          subtitle={`@${group.url}`}
          statistics={[
            {label: 'events', value: events.length},
            {label: 'attendees', value: group.numOfAttendees},
          ]}
          instagram={group.socials?.instagram}
          twitter={group.socials?.twitter}
          linkedIn={group.socials?.linkedIn}
          upcomingEvent={upcomingEvents[0]}
          pastEvents={pastEvents}
          verified={group.verified}
          customLinks={group.groupLinks}
          upcomingEvents={upcomingEvents}
          cover={group.header}
          website={group.socials?.website}
          location={group.location}
          bio={group.bio}
          isFollowedByViewer={isCurrentUserFollowingGroup}
          url={group.url}
        />
      </div>

      <PoshMobileNav />
    </>
  )
}

export default GroupProfile
