import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {useFetchDispute} from 'apis/Disputes/useFetchDispute'
import {useFetchGroup} from 'apis/Groups/useFetchGroup'
import {useFetchHostByTrackingLink} from 'apis/Orders/useFetchHostByTrackingLink'
import {useFetchOrderPageData} from 'apis/Orders/useFetchOrder'
import getEventFlyer from 'apis/Util/getEventFlyer'
import CheckoutFieldResponses from 'components/CheckoutFieldResponses/CheckoutFieldResponses'
import {SpinLoader} from 'components/Loaders/SpinLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import RequirePermissions, {requirePermissionAndScope} from 'components/RequirePermissions'
import {useToast} from 'components/toasts/ToastProvider'
import {isEmpty} from 'lodash'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import OrderActionRow from './OrderActionRow'
import OrderCardAffiliation from './OrderPageCards/affiliate'
import OrderCardCanceled from './OrderPageCards/canceled'
import OrderCardComp from './OrderPageCards/complimentary'
import OrderCardCustomer from './OrderPageCards/customer'
import OrderCardDispute from './OrderPageCards/dispute'
import OrderCardFraudAnalysis from './OrderPageCards/fraudanalysis'
import OrderCardHost from './OrderPageCards/host'
import OrderCardPayments from './OrderPageCards/payments'
import OrderCardRefund from './OrderPageCards/refund'
import OrderCardTables from './OrderPageCards/tables'
import OrderCardTickets from './OrderPageCards/tickets'
import OrderCardTimeline from './OrderPageCards/timeline'
import TransferOrderCard from './OrderPageCards/transferOrder'

const OrderPage = () => {
  const navigate = useNavigate()
  const {orderId} = useParams<{orderId: string}>()
  const {groupId} = useParams<{groupId: string}>()
  const {showToast} = useToast()

  const {data: res} = useFetchOrderPageData(orderId, {
    onError: (error: any) => {
      showToast({type: 'error', title: error.response.data.error})
      navigate(-1)
    },
  })

  const order = res?.order
  const event = res?.event
  const affiliation = res?.affiliation
  const riskLevel = res?.riskLevel
  const riskScore = res?.riskScore
  const attendeeId = order?.accountID

  const shouldRenderRiskAnalysis = !!riskLevel && riskLevel !== 'not_assessed' && riskScore

  const {data: groupData} = useFetchGroup(groupId!)
  const {data: host} = useFetchHostByTrackingLink(order?.trackingLink ?? '')

  const {data: dispute} = useFetchDispute(order?.disputeId)

  const handleEventPageRedirect = () => {
    navigate(`/e/${event?.url}`)
  }
  return (
    <div className='GroupPage'>
      <div className='GroupPage-content'>
        <div className='GroupPage-content-inner'>
          {order && event && groupData ? (
            <>
              <div className='OrderPage-headerCont'>
                <h5 className='clickable' onClick={handleEventPageRedirect}>
                  <PoshImage src={getEventFlyer(event)} />
                  {event.name}
                </h5>
                <div className='OrderPage-header'>
                  <h2>
                    <BackAndTitle />
                    Order #{order.number}
                  </h2>
                  <OrderActionRow key={order._id} order={order} currency={groupData!.currency} />
                </div>
              </div>

              <div className='GroupPage-cols'>
                <div className='expand'>
                  {dispute && <OrderCardDispute dispute={dispute} />}
                  {order.canceled && <OrderCardCanceled />}
                  {order.refundMetaData?.refundedAt && (
                    <OrderCardRefund
                      refundMetaData={order.refundMetaData}
                      isPaymentPlan={!isEmpty(order.paymentPlan)}
                      currency={groupData.currency}
                    />
                  )}
                  {order.isComplimentaryOrder && <OrderCardComp />}
                  {order.fromTransferDocId && <TransferOrderCard />}
                  {order.tickets && order.tickets.length > 0 && <OrderCardTickets order={order} />}
                  {order.tables && order.tables.length > 0 && <OrderCardTables order={order} />}
                  {!order.isComplimentaryOrder && <OrderCardPayments order={order} />}
                  <OrderCardTimeline order={order} dispute={dispute} />
                  {event._id && (
                    <CheckoutFieldResponses
                      responses={order.customFieldResponses?.map(customFieldResponse => {
                        return {
                          ...customFieldResponse,
                          eventId: event._id,
                        }
                      })}
                    />
                  )}
                </div>
                <div className='small'>
                  <RequirePermissions requiredPermissions={[requirePermissionAndScope('view_orders_list', ['group'])]}>
                    {attendeeId && groupId && <OrderCardCustomer attendeeId={attendeeId} groupId={groupId} />}
                  </RequirePermissions>
                  {affiliation && <OrderCardAffiliation affiliation={affiliation} order={order} event={event} />}
                  {host && order?.trackingLink && <OrderCardHost host={host} trackingLink={order.trackingLink} />}
                  {shouldRenderRiskAnalysis && <OrderCardFraudAnalysis riskScore={riskScore} riskLevel={riskLevel} />}
                </div>
              </div>
            </>
          ) : (
            <SpinLoader height={100} />
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderPage
