import React, {useState} from 'react'
import {useParams} from 'react-router-dom'

import {CreateRefundRequestInputs, useCreateRefundRequest} from 'apis/Events/useCreateRefundRequest'
import Button from 'components/form/Button'
import Select from 'components/form/Select'
import TextArea from 'components/form/TextArea'
import PoshStyledModal from 'components/modals/Modal/PoshStyledModal'
import {useToast} from 'components/toasts/ToastProvider'

import {RefundReason, RefundRequestModalProps} from './types'
import {localSchemaVal, submitSchemaVal} from './validators'

import './styles.scss'

export const RefundRequestModal = (props: RefundRequestModalProps) => {
  const {isOpen, onClose, triggerCheckRefetch, isCheckFetching} = props
  const {mutateAsync: createRefundRequest, isLoading} = useCreateRefundRequest()
  const {showToast} = useToast()
  const {eventId} = useParams()
  const blankRefundRequest: CreateRefundRequestInputs = {
    eventId: eventId!,
    reason: '',
    description: '',
    internalNoteForPosh: '',
    supportInstructions: '',
  }
  const [refundRequest, setRefundRequest] = useState(blankRefundRequest)
  const isLoadingButton = isLoading || isCheckFetching
  const disableButton =
    isLoading ||
    refundRequest.reason === '' ||
    refundRequest.description === '' ||
    refundRequest.internalNoteForPosh === '' ||
    refundRequest.supportInstructions === ''

  // Update state when user types in form
  const handleChange = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    const newRefundRequest = {...refundRequest, [name]: value}
    try {
      localSchemaVal.validateSync(newRefundRequest)
      setRefundRequest(newRefundRequest)
    } catch {
      showToast({type: 'error', title: 'Invalid input'})
    }
  }

  const handleSubmit = async () => {
    try {
      submitSchemaVal.validateSync(refundRequest)
      const {message} = await createRefundRequest({...refundRequest})
      await triggerCheckRefetch()
      setRefundRequest(blankRefundRequest)
      showToast({type: 'success', title: message})
      onClose()
    } catch (err) {
      if (err.name === 'ValidationError') {
        showToast({type: 'error', title: 'Please fill out all fields'})
        return
      }
      showToast({type: 'error', title: err.message})
    }
  }

  return (
    <PoshStyledModal isOpen={isOpen} onClose={onClose}>
      <div className='MassRefundForm'>
        <h3 className='center'>Refund Full Event Refund</h3>
        <p className='center'>
          If you would like to refund EVERY ATTENDEE of your event all at once, fill out the brief form below to submit
          a request.
        </p>
        <Select name='reason' id='reason' className='MassRefundForm-Dropdown' onChange={handleChange}>
          <option value='' className='MassRefundForm-Dropdown-Option'>
            Select a refund reason*
          </option>
          <option value={RefundReason.VenueIssues}>{RefundReason.VenueIssues}</option>
          <option value={RefundReason.WeatherIssues}>{RefundReason.WeatherIssues}</option>
          <option value={RefundReason.ArtistCancellation}>{RefundReason.ArtistCancellation}</option>
          <option value={RefundReason.Other}>{RefundReason.Other}</option>
        </Select>
        <TextArea
          placeholder='Elaborate on the reason for the refund (internal note to POSH)*'
          name='internalNoteForPosh'
          onChange={handleChange}
        />
        <TextArea
          placeholder='Write an update for the attendees (sent to all attendees if request is approved)*'
          name='description'
          onChange={handleChange}
        />
        <TextArea
          placeholder='Let attendees know where questions and concerns should be directed*'
          className='MassRefundForm-Contact'
          name='supportInstructions'
          onChange={handleChange}
        />
        <p className='grey text-xxs noMargin' style={{padding: '0 30px'}}>
          Requests are processed every Friday, and if approved can take 3-5 business days to be issued. Processing fees
          are not refunded.
        </p>
        <Button onClick={handleSubmit} className='gold' isLoading={isLoadingButton} disabled={disableButton}>
          {isLoadingButton ? 'Requesting Refund...' : 'Request Full Event Refund'}
        </Button>
      </div>
    </PoshStyledModal>
  )
}
