import React, {PropsWithChildren, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {DEFAULT_ACCOUNT_AVI} from '@posh/model-types'
import {GUESTLIST_DISPLAY_THRESHOLD, HIDDEN_LOCATION_COPY} from '@posh/types'
import {FetchFeaturedEventsOutput, useFetchFeaturedEvents} from 'apis/Events/EventMarketplace/useFetchFeaturedEvents'
import {useMixpanel} from 'apis/MixPanelHandler'
import getAvi from 'apis/Util/getAvi'
import Button from 'components/form/Button'
import {PoshBoatLoader} from 'components/Loaders/PoshBoatLoader'
import {PoshImage} from 'components/PoshImage/PoshImage'
import UserListPreview from 'components/UserListPreview/UserListPreview'
import moment from 'moment-timezone'

import './styles.scss'

const FeaturedEventsDots = ({
  n,
  onClick,
  activeIndex = 0,
}: {
  n: number
  onClick: (index: number) => void
  activeIndex?: number
}) => {
  return (
    <div className='FeaturedEvents-dots'>
      {[...Array(n)].map((_, index) => {
        const isActive = activeIndex === index
        const backgroundColor = '#fff' && isActive ? '#fff' : undefined
        return (
          <div
            key={index}
            className='FeaturedEvents-dots-dot'
            onClick={() => onClick(index)}
            style={{background: backgroundColor}}
          />
        )
      })}
    </div>
  )
}

const FeaturedEventMainDetails = ({
  name,
  venue,
  startUtc,
  lightmode,
  accentColor,
  timezone,
}: Pick<FetchFeaturedEventsOutput[0], 'name' | 'venue' | 'startUtc' | 'lightmode' | 'accentColor' | 'timezone'>) => {
  return (
    <>
      <div className='FeaturedEvents-event-info-timePlace'>
        <h2 style={{color: `${lightmode ? accentColor : '#FAFAFC'}`}}>{name}</h2>
        <p style={{color: `${lightmode ? accentColor : '#FAFAFC'}`}}>
          {venue.name ? venue.name : HIDDEN_LOCATION_COPY}
        </p>
        <p style={{color: `${lightmode ? accentColor : '#FAFAFC'}`}}>
          {moment.tz(startUtc, timezone).format('dddd MMMM D, h:mm A')}
        </p>
      </div>
    </>
  )
}

const FeaturedEventLineupAct = ({
  actAvi,
  children,
  lightmode,
  accentColor,
  onClickGetTickets,
}: PropsWithChildren<{actAvi: string; lightmode?: boolean; accentColor?: string; onClickGetTickets: () => void}>) => {
  const border = `1px solid ${lightmode ? `${accentColor}88` : '#777'}`

  return (
    <div className='FeaturedEvents-event-info-lineupAct' style={{border}} onClick={onClickGetTickets}>
      <PoshImage src={actAvi} />
      <h5 style={{color: `${lightmode ? accentColor : '#FAFAFC'}`}}>{children}</h5>
    </div>
  )
}

const FeaturedEventAttendeeList = ({
  totalGoing,
  accentColor,
  lightmode,
  children,
}: PropsWithChildren<{
  totalGoing: number
  accentColor?: string
  lightmode?: boolean
}>) => {
  return (
    <div className='EventPage-preview-list'>
      {children}
      {totalGoing > 0 && (
        <p style={{color: `${lightmode ? accentColor : '#FAFAFC'}`}} className='EventPage-totalAttending'>
          {totalGoing}+ Going
        </p>
      )}
    </div>
  )
}

const getBackgroudColor = (lightmode?: boolean, accentColor?: string) => {
  return lightmode ? `#fff` : `#222325`
}

export const FeaturedEvents = () => {
  // Add auto cycling after x seconds?
  const [selectedEventIndex, setSelectedEventIndex] = useState(0)
  const [backgroundColor, setBackgroundColor] = useState('')
  const timerRef = useRef<Timer | null>(null)
  const {data: featuredEvents, isLoading} = useFetchFeaturedEvents()
  const navigate = useNavigate()
  const {trackEvent: trackMixpanelEvent} = useMixpanel()
  const onClickDot = (index: number) => {
    setSelectedEventIndex(index)
    if (timerRef.current) clearTimeout(timerRef.current)
  }

  useEffect(() => {
    // We preload images to avoid flickering when changing images
    if (featuredEvents) {
      featuredEvents.forEach(v => {
        const img = new Image()
        img.src = v.flyer ?? ''
      })
    }

    if (timerRef.current) clearTimeout(timerRef.current)
    timerRef.current = setInterval(() => {
      setSelectedEventIndex(selectedEventIndex => (selectedEventIndex + 1) % (featuredEvents?.length ?? 1))
    }, 10000)
    return () => {
      if (timerRef.current) clearInterval(timerRef.current)
    }
  }, [featuredEvents])

  useEffect(() => {
    if (!featuredEvents?.length) return
    setBackgroundColor(
      getBackgroudColor(
        featuredEvents?.[selectedEventIndex].lightmode,
        featuredEvents?.[selectedEventIndex].accentColor,
      ),
    )
  }, [selectedEventIndex, featuredEvents])

  if (isLoading) {
    return (
      <div className='FeaturedEvents-loading'>
        <PoshBoatLoader />
      </div>
    )
  }

  if (!isLoading && (!featuredEvents || !featuredEvents?.length)) {
    return null
  }

  const selectedEvent = featuredEvents?.[selectedEventIndex]
  const {
    name,
    venue,
    flyer,
    startUtc,
    url,
    accentColor,
    attendees,
    totalGoing,
    lineup,
    lightmode,
    attendanceDisplayDisabled,
    timezone,
  } = selectedEvent ?? {}
  const isGuestListEnabled = selectedEvent?.guestlistEnabled
  const isDisplayThresholdSatisfied = (totalGoing ?? 0) > GUESTLIST_DISPLAY_THRESHOLD
  const showGuestlist = isGuestListEnabled && isDisplayThresholdSatisfied
  const featuredLineup = (lineup?.length ?? 0) > 0 ? lineup?.slice(0, 2) : undefined
  const adjustedAttendeeCount = !attendanceDisplayDisabled && totalGoing ? totalGoing : 0
  const onClickGetTickets = () => {
    if (url) {
      trackMixpanelEvent('Get Tickets Button-  Explore Page', {
        eventUrl: url,
        numberOfEventsInFeaturedSection: featuredEvents?.length.toString() ?? '0',
        positionInFeaturedSection: String(selectedEventIndex + 1),
        isGuestlistShown: String(isGuestListEnabled),
        numberOfGuestsShown: String(totalGoing),
      })
      navigate(`/e/${url}?t=posh`)
    }
  }

  return (
    <div
      className='FeaturedEvents'
      style={{
        background: backgroundColor,
      }}>
      <div
        className={'EventPage-bg'}
        style={{
          backgroundImage: `url(${flyer})`,
        }}
      />
      <div className='FeaturedEvents-event'>
        <PoshImage className='EventPage-eventImage' src={flyer} />
        <div className='FeaturedEvents-event-info'>
          <FeaturedEventMainDetails
            name={name}
            venue={venue}
            startUtc={startUtc}
            accentColor={accentColor}
            lightmode={lightmode}
            timezone={timezone}
          />
          {featuredLineup && (
            <div className='FeaturedEvents-event-info-lineup'>
              {featuredLineup.map((act, index) => (
                <FeaturedEventLineupAct
                  key={index}
                  actAvi={act.avi}
                  accentColor={accentColor}
                  lightmode={lightmode}
                  onClickGetTickets={onClickGetTickets}>
                  {act.name}
                </FeaturedEventLineupAct>
              ))}
            </div>
          )}
          {showGuestlist && (
            <FeaturedEventAttendeeList
              totalGoing={adjustedAttendeeCount}
              accentColor={accentColor}
              lightmode={lightmode}>
              <UserListPreview>
                {attendees?.map((attendee, index) => {
                  if (getAvi(attendee) === DEFAULT_ACCOUNT_AVI) return null
                  return (
                    <UserListPreview.User
                      key={index}
                      imageSrc={getAvi(attendee)}
                      alt='Profile picture of user attending featured Posh event'
                    />
                  )
                })}
              </UserListPreview>
            </FeaturedEventAttendeeList>
          )}
          <div>
            <Button
              onClick={onClickGetTickets}
              style={{
                color: lightmode ? '#fff' : '#000',
                background: lightmode ? accentColor : '#fff',
              }}>
              Get Tickets
            </Button>
          </div>
        </div>
      </div>
      <FeaturedEventsDots n={featuredEvents?.length ?? 0} onClick={onClickDot} activeIndex={selectedEventIndex} />
    </div>
  )
}
