import React, {useState} from 'react'
import Loader from 'react-loader-spinner'

import {useQueryClient} from '@tanstack/react-query'
import {useCreateRelease} from 'apis/Releases/useCreateRelease'
import {ReleaseListItem, useListReleases} from 'apis/Releases/useListReleases'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import PoshMarkdownEditor from 'components/form/PoshMarkdownEditor'
import {SafeMarkdownDisplay} from 'components/Text/SafeMarkdownDisplay'
import {useToast} from 'components/toasts/ToastProvider'
import moment from 'moment'
import BackAndTitle from 'pages/GroupPage/PageComponents/BackAndTitle'

import ConfirmationModal from './ConfirmationModal'

import './styles.scss'

const ReleaseRow = (props: {release: ReleaseListItem}) => {
  const {release} = props

  return (
    <tr className='ReleaseRow'>
      <td>{release.version}</td>
      <td>{moment(release.createdAt).format('LLLL')}</td>
      <td>
        <SafeMarkdownDisplay className='ReleaseDashboard-markdown-preview' source={release.updatesMarkdown} />
      </td>
    </tr>
  )
}

const ReleaseNotificationCreate = () => {
  const [release, setRelease] = useState({version: '', updatesMarkdown: ''})
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const {showToast} = useToast()
  const queryClient = useQueryClient()

  const {mutateAsync: createRelease} = useCreateRelease({
    onSuccess: () => {
      showToast({type: 'success', title: 'Created a new release!'})
      queryClient.invalidateQueries(['releases'])
      setRelease({version: '', updatesMarkdown: ''})
      setShowConfirmationModal(false)
    },
    onError: () => showToast({type: 'error', title: 'Error in creating the new release.'}),
  })

  const createReleaseOnClick = async () => {
    await createRelease({...release})
  }

  const setVersion = (version: string) => {
    setRelease(prev => {
      return {...prev, version}
    })
  }

  const setUpdatesMarkdown = (updatesMarkdown: string) => {
    setRelease(prev => {
      return {...prev, updatesMarkdown}
    })
  }

  return (
    <>
      <h2>New Release</h2>
      <div>
        <label htmlFor='version'>Version</label>
        <Input
          placeholder='Ex. 3.0.2'
          id='version'
          value={release.version}
          onChange={e => setVersion(e.target.value)}
        />
      </div>
      <h3 className='center'>Updates</h3>
      <div className='ReleaseDashboard-updates'>
        <PoshMarkdownEditor
          value={release.updatesMarkdown}
          onChange={value => setUpdatesMarkdown(value)}
          toolBarSize='medium'
        />
        <SafeMarkdownDisplay
          className='ReleaseDashboard-markdown-preview'
          source={release.updatesMarkdown}></SafeMarkdownDisplay>
      </div>

      <Button className='ReleaseDashboard-createButton' onClick={() => setShowConfirmationModal(true)}>
        Create Release
      </Button>
      {showConfirmationModal && (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          createReleaseOnClick={createReleaseOnClick}
          release={release}
        />
      )}
    </>
  )
}

const ReleaseNotificationDashboard = () => {
  const {data, isFetching: isLoading, isError, isSuccess} = useListReleases()
  return (
    <div className='ReleaseDashboard'>
      <BackAndTitle title={'Release Notifications'} />
      <ReleaseNotificationCreate />
      <h2>Previous Releases</h2>
      {isLoading && <Loader type={'TailSpin'} />}
      {isError && <p>Something went wrong when fetching previous releases...</p>}
      {isSuccess && (
        <table>
          <tbody>
            <tr>
              <th>Release Version</th>
              <th>Date Released</th>
              <th>Release Notes</th>
            </tr>
            {data?.releases.map(release => (
              <ReleaseRow key={release.version} release={release} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default ReleaseNotificationDashboard
