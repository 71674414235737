import React, {useMemo} from 'react'

import {Ticket} from 'apis/Events/types'
import {OrdersForTicket} from 'apis/Orders'
import {CRUDTable} from 'components/TableUI/CRUDTable/CRUDTable'
import {ColumnConfig} from 'components/TableUI/CRUDTable/internals/types'
import {TableCell} from 'components/TableUI/TableCell/TableCell'
import {useNavigateToOrgPages} from 'hooks/navigation/useNavigateToOrgPages'

interface TicketOrdersTableProps {
  ticket: Ticket
  ordersAndNames?: OrdersForTicket[]
  onRefresh: () => void
  dataUpdatedAt: number
}

const TicketOrdersTable = (props: TicketOrdersTableProps) => {
  const {ticket, ordersAndNames, dataUpdatedAt, onRefresh} = props
  const {navigateToOrderPage} = useNavigateToOrgPages()

  const columnConfigs = useMemo<ColumnConfig<OrdersForTicket>[]>(() => {
    return [
      {
        header: 'Name',
        key: 'accountName',
        render: accountName => <TableCell.Text text={accountName} bold />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        header: 'Email',
        key: 'accountEmail',
        render: accountEmail => <TableCell.Text text={accountEmail ?? 'No Email'} />,
      },
      {
        header: 'Phone',
        key: 'phone',
        render: phone => <TableCell.Text text={phone ?? 'No Phone'} />,
      },
      {
        header: 'Date Created',
        key: 'createdAt',
        render: createdAt => <TableCell.Text text={createdAt ? new Date(createdAt).toLocaleDateString() : 'No Date'} />,
        sortConfig: {
          defaultSort: true,
        },
      },
      {
        header: 'Qty',
        key: 'tickets',
        render: tickets => <TableCell.Text text={tickets.length.toString()} />,
        sortConfig: {
          defaultSort: true,
        },
      },
    ]
  }, [])

  return (
    <div style={{paddingBottom: '200px'}}>
      <CRUDTable
        data={ordersAndNames ?? []}
        lastUpdated={dataUpdatedAt}
        refresh={onRefresh}
        columns={columnConfigs}
        itemsPerPage={10}
        resourceName={`Orders for ${ticket.name}`}
        searchableColumn='accountName'
        onClickRow={order => navigateToOrderPage(order._id)}
      />
    </div>
  )
}

export default TicketOrdersTable
